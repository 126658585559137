import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PAGE_SIZE } from '../config/pagination';
// import { IUserMailNotificationSettings, IUserMailSettingsData } from '../models';

@Injectable({ providedIn: 'root' })
export class LocationsService {
  constructor(private _http: HttpClient) {}

  public getLocationsList(data: any = {}) {
    const params = {
      $count: 'true',
      $top: `${PAGE_SIZE.DEFAULT}`,
    };

    return this._http.get<any>(`api/locations`, { params: params });
  }

  public getLocationsFormdata() {
    return this._http.get('api/locations/formData');
  }

  public getLocationById(id: string) {
    return this._http.get<any>(`api/locations/${id}`);
  }

  public saveLocation(req: any, id?: string) {
    if (id) {
      return this._http.post<any>(`api/locations/${id}`, req);
    }
    return this._http.post<any>(`api/locations`, req);
  }

  public deleteLocation(id: string) {
    return this._http.delete<any>(`api/locations/${id}`);
  }
}
